import { useApolloClient } from '@apollo/client';
import { useEffect, useState } from "react";
import GET_DATA from './getData.graphql';
import PUBLISH from './publish.graphql';
import PUBLISH_ALL from './publishAll.graphql';
import REJECT from './reject.graphql';

const LIMIT_PER_PAGE = 100

export default (shouldFetch) => {
  const client = useApolloClient()
  const [bookRecords, setBookRecords] = useState([])
  const [page, setPage] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)
  const [forceNetwork, setForceNetwork] = useState(true)

  useEffect(() => {
    if (!loading && bookRecords.length === 0) {
      fetch()
    }
    // eslint-disable-next-line
  }, [loading, bookRecords])

  useEffect(() => {
    if (!shouldFetch) return
    if (hasMore) {
      // fetch()
    } else {
      setLoading(false)
      setForceNetwork(false)
    }
  }, [shouldFetch, page, hasMore])

  useEffect(() => {
    setPage(0)
    setHasMore(true)
  }, [forceNetwork])

  const fetch = async () => {
    if (!hasMore) return
    setLoading(true)
    const { data } = await client.query({
      query: GET_DATA,
      variables: {
        skip: page * LIMIT_PER_PAGE,
        limit: LIMIT_PER_PAGE
      },
      fetchPolicy: forceNetwork ? 'network-only' : 'cache-first'
    })
    setHasMore(false)
    if (data && data.tutoring && data.tutoring.bookRecords) {
      setBookRecords([...bookRecords, ...data.tutoring.bookRecords])
      if (data.tutoring.bookRecords.length < LIMIT_PER_PAGE) {
        setHasMore(false)
      } else {
        // setPage(page + 1)
      }
      setLoading(false)
    }
    setHasMore(false)
  }

  const removeFromList = (_id) => {
    setBookRecords(bookRecords.filter(c => c._id !== _id))
  }

  const publish = async ({ _id, taskType }, featureAnimal) => {
    const { errors } = await client.mutate({
      mutation: PUBLISH,
      variables: { bookRecordId: _id, taskType },
    })
    if (!errors) {
      removeFromList(_id)
    }
  }

  const reject = async ({ _id }) => {
    const { errors } = await client.mutate({
      mutation: REJECT,
      variables: { bookRecordId: _id },
    })
    if (!errors) {
      removeFromList(_id)
    }
  }

  const publishAll = async () => {
    const { errors } = await client.mutate({
      mutation: PUBLISH_ALL,
      variables: { bookRecords: bookRecords.map(d => d._id) }
    })
    if (!errors) {
      setBookRecords([])
    }
  }


  return { bookRecords, loading, publish, publishAll, reject }
}
