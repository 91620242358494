import { Button as GrommetButton } from "grommet"
import { Checkmark, Close } from "grommet-icons"
import React, { useState } from "react"
import { colors } from "../../theme"
import Button from "../UI/Button"
import Loader from "../UI/Loader"
import Reading from "../UI/Reading"
import { Actions, Content, Wrapper } from "./index.styles"
import useBookRecordsFeed from "./useBookRecordsFeed"

const BookRecordsFeed = props => {
  const {
    bookRecords,
    loading,
    publishAll,
    publish,
    reject,
  } = useBookRecordsFeed(true)
  const [nbRequiredPress, setNbRequiredPress] = useState(3)

  if (loading) return <Loader />

  const onPublishAllPressed = () => {
    if (nbRequiredPress <= 1) {
      setNbRequiredPress(3)
      return publishAll()
    }
    setNbRequiredPress(nbRequiredPress - 1)
  }

  return (
    <Wrapper>
      <Actions>
        <div>{bookRecords.length} records</div>
        <Button bgColor={colors.green} onClick={onPublishAllPressed}>
          Publish all ({nbRequiredPress})
        </Button>
      </Actions>
      <Content>
        {bookRecords.map(bookRecord => (
          <Reading
            {...bookRecord}
            key={bookRecord._id}
            actions={[
              <GrommetButton
                primary
                key="reject-bookRecord"
                color={colors.red}
                onClick={() => reject(bookRecord)}
                icon={<Close color="#fff" />}
              />,
              <GrommetButton
                primary
                key="publish-bookRecord"
                color={colors.green}
                onClick={() => publish(bookRecord)}
                icon={<Checkmark />}
              />,
            ].filter(d => d)}
          />
        ))}
      </Content>
    </Wrapper>
  )
}

BookRecordsFeed.propTypes = {}

BookRecordsFeed.defaultProps = {}

export default BookRecordsFeed
