import React from "react"

import BookRecordsFeed from "../components/BookRecordsFeed";

const BookRecordsFeedPage = () => {
  if (typeof window === "undefined") return null

  return <BookRecordsFeed />
}

export default BookRecordsFeedPage
